<template>
  <div class="d-flex align-items-center mr-4 w-100">
    <b-form-group class="flex-grow-1" :label="translations.filters.claim_id">
      <b-form-tags
      v-model="filter_set.claim_keys"
      tag-variant="info"
      separator=" "
      no-add-on-enter
      :disabled="loading"
      placeholder=""/>
    </b-form-group>
    <b-form-group class="flex-grow-1" :label="translations.filters.client">
      <multiselect
        v-model="filter_set.clients"
        :options="filter_options.clients"
        :multiple="true"
        :showLabels="false"
        label="text"
        track-by="value"
        :disabled="loading"
        :allow-empty="false"
        @select="checkInput('clients', ...arguments)"/>
    </b-form-group>
    <b-form-group class="flex-grow-1" :label="translations.filters.payer">
      <multiselect
      v-model="filter_set.payers"
      :options="filter_options.payers"
      :multiple="true"
      :showLabels="false"
      label="text"
      track-by="value"
      :disabled="loading"
      :allow-empty="false"
      @select="checkInput('payers', ...arguments)"/>
    </b-form-group>
    <b-form-group class="flex-grow-1" :label="translations.filters.assignee">
      <multiselect
        v-model="filter_set.assignees"
        :options="assigneeOptions"
        :multiple="true"
        :showLabels="false"
        label="text"
        track-by="value"
        :disabled="loading"
        :allow-empty="false"
        @select="checkInput('assignees', ...arguments)"/>
    </b-form-group>
    <b-button
      data-testid="btn-search"
      class="mt-2"
      variant="primary"
      :disabled="loading"
      @click="applyFilters">
      <feather type="search"></feather>
    </b-button>
  </div>
</template>

<script>
import translations from '@/translations';
import { CLAIM_ASSIGNEES } from '@/constants/finance';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ClaimFollowUpTasksFilters',
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      translations: translations.finance_operations.claims_follow_up_tasks,
      filter_set: {
        claim_keys: [],
        assignees: [],
        clients: [],
        payers: [],
      },
      filter_options: {
        clients: [],
        payers: [],
      },
    };
  },
  computed: {
    assigneeOptions() {
      const claimAssignees = CLAIM_ASSIGNEES.map(claimAssign => ({
        value: claimAssign,
        text: claimAssign,
      }));

      return [
        { value: '', text: this.translations.filters.default_all },
        { value: 'unassigned', text: this.translations.filters.blank_option_assignee_filter },
        ...claimAssignees,
      ];
    },
  },
  methods: {
    async fetchClients() {
      try {
        this.loading = true;
        const clients = await this.$store.dispatch('Core/fetchAllClients');
        const clientsSorted = clients?.sort((a, b) => a.display_name.localeCompare(b.display_name));
        const clientsOptions = clientsSorted.map(client => ({
          value: client.id,
          text: `${client.display_name} (ID: ${client.id})`,
        }));

        this.filter_options.clients = [
          { value: '', text: this.translations.filters.default_all },
          ...clientsOptions,
        ];
      } catch (_) {
        this.$noty.error(this.translations.errors.fetch_clients);
      } finally {
        this.loading = false;
      }
    },
    async fetchSystemPayers() {
      try {
        this.loading = true;
        const { data } = await this.$store.dispatch('Financial/getSystemPayers');
        const payers = data?.data?.system_payers || [];

        const payerOptions = payers.map(payer => ({
          value: payer.id,
          text: payer.payer_name,
        }));

        this.filter_options.payers = [
          { value: '', text: this.translations.filters.default_all },
          ...payerOptions,
        ];
      } catch (_) {
        this.$noty.error(this.translations.errors.fetch_payers);
      } finally {
        this.loading = false;
      }
    },
    // this method is called from the parent component (mounted hook)
    async recoverFiltersFromURL(filters = {}) {
      await Promise.all([
        this.fetchClients(),
        this.fetchSystemPayers(),
      ]);

      this.filter_set = {
        claim_keys: filters.claim_keys || [],
        assignees: { value: '', text: this.translations.filters.default_all },
        clients: { value: '', text: this.translations.filters.default_all },
        payers: { value: '', text: this.translations.filters.default_all },
      };

      if (filters.assignees?.length > 0) {
        this.filter_set.assignees = filters.assignees.map(assignee => {
          const assigneeOption = this.assigneeOptions.find(opt => opt.value === assignee);
          if (!assigneeOption) return null;
          return assigneeOption;
        });
      }

      if (filters.client_ids?.length > 0) {
        this.filter_set.clients = filters.client_ids.map(clientId => this.filter_options.clients.find(c => c.value === +clientId));
      }

      if (filters.system_payer_ids?.length > 0) {
        this.filter_set.payers = filters.system_payer_ids.map(payerId => this.filter_options.payers.find(p => p.value === +payerId));
      }
    },
    applyFilters() {
      this.loading = true;
      const filtersToSend = {
        claim_keys: this.filter_set?.claim_keys || [],
        assignees: [],
        client_ids: [],
        system_payer_ids: [],
      };

      if (this.filter_set.assignees.length > 0) {
        this.filter_set.assignees.forEach(assignee => {
          if (assignee.value !== '') {
            filtersToSend.assignees.push(assignee.value);
          }
        });
      }

      if (this.filter_set.clients.length > 0) {
        this.filter_set.clients.forEach(client => {
          if (client.value !== '') {
            filtersToSend.client_ids.push(client.value);
          }
        });
      }

      if (this.filter_set.payers.length > 0) {
        this.filter_set.payers.forEach(payer => {
          if (payer.value !== '') {
            filtersToSend.system_payer_ids.push(payer.value);
          }
        });
      }

      this.$emit('apply-filters', filtersToSend);
      this.loading = false;
    },
    checkInput(name, { value }) {
      if (value === '') {
        this.filter_set[name] = [ { value: '', text: this.translations.filters.default_all } ];
      } else if (this.filter_set[name][0].value === '') {
        this.filter_set[name].splice(0, 1);
      }
    },
  },
};
</script>
