export const CLAIM_FOLLOW_UP_TASK_STATUS = {
  to_do: 'To Do',
  in_progress: 'In Progress',
  completed: 'Completed',
};

export const CLAIM_FOLLOW_UP_NEXT_ACTIONS = {
  send_listing_to_payer: 'Send listing to Payer',
  follow_up_with_payer: 'Follow up with Payer',
  check_eobs: 'Check EOBs',
  escalate_to_csm: 'Escalate to CSM',
};

export const CLAIM_WRITE_OFF_TASK_REASONS = {
  duplicate_claim: 'Revenue Adjustment: Duplicate Claim',
  inactive_policy: 'Revenue Adjustment: Inactive Policy',
  incorrect_payer_cob: 'Incorrect Payer/COB',
  incorrect_member_id: 'Incorrect/Missing Member ID',
  aged_and_non_collectible: 'Aged & Non-collectible',
  patient_responsibility: 'Patient Responsibility (Medical Provider)',
};

export const CLAIM_WRITE_OFF_TASK_STATUS = {
  pending_approval: 'Pending Approval',
  approved: 'Approved',
};

export const CLAIM_WRITE_OFF_TASK_NEXT_ACTIONS = {
  pending_approval_revops_associate: 'Pending Approval - RevOps Associate',
  pending_approval_revops_manager: 'Pending Approval - RevOps Manager',
  pending_approval_vp: 'Pending Approval - VP of FP&A/Controller',
  pending_approval_cfo: 'Pending Approval - CFO',
  pending_approval_ceo: 'Pending Approval - CEO',
};
