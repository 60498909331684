<template>
  <financial-list
    ref="list"
    v-model="currentPage"
    :header-data="TABLE_HEADERS"
    :events="TABLE_EVENTS"
    :data="list"
    :disabled="loading"
    :items-per-page="itemsPerPage"
    :total="total"
    :no-items-message="translations.list.empty_table"
    itemRefKey="id"
    selectable
    @page-changed="fetchList"
    @row-details="handleDetails"
    @select-changed="onSelectChange"/>
</template>

<script>
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import service from '@/services/finance-service';
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import { formatDate, formatValue } from '@/helpers/finance';
import { cloneDeep } from 'lodash';

export default {
  name: 'ClaimFollowUpTasksListCompletedTasks',
  components: {
    FinancialList,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.list.claim_id, 'claim.unique_key'),
      associateHeaderDataItem(this.translations.list.client_name, 'claim_account.client_name'),
      associateHeaderDataItem(this.translations.list.payer_name, 'claim_account.payer_name'),
      associateHeaderDataItem(this.translations.list.assignee, 'claim_account.assignee'),
      associateHeaderDataItem(this.translations.list.claim_submitted_date, 'submitted_date'),
      associateHeaderDataItem(this.translations.list.billed_amount, 'total_amount'),
      associateHeaderDataItem(this.translations.list.paid_amount, 'paid_amount'),
      associateHeaderDataItem(this.translations.list.completed_date, 'completed_date'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: this.translations.list.view_details,
        icon: 'eye',
      },
    };
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      loading: true,
      list: [],
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
      appliedFilters: {},
    };
  },
  async beforeMount() {
    const page = this.$route.query?.page ? Number(this.$route.query.page) : 1;
    this.currentPage = page;
    this.total = page * this.itemsPerPage;
  },
  mounted() {
    this.$emit('mounted');
  },
  methods: {
    async applyFilters(filters, page = 1) {
      this.$refs.list.clearSelection();
      this.currentPage = page;
      this.appliedFilters = cloneDeep(filters);

      await this.fetchList(this.currentPage);

      this.$emit('get-total', this.total);
    },
    async fetchList(page) {
      this.loading = true;
      this.currentPage = page;

      const offset = (page - 1) * this.itemsPerPage;

      try {
        const { claim_follow_up_tasks: claimFollowUpTasks, total } = await service.getClaimFollowUpTasks({
          ...this.appliedFilters,
          is_status_completed: true,
          order_by: 'completed_date',
          order_descending: true,
          offset,
          limit: this.itemsPerPage,
        });

        this.list = claimFollowUpTasks.map(claimFUT => ({
          ...claimFUT,
          submitted_date: formatDate(claimFUT.claim.submitted_date),
          total_amount: formatValue(claimFUT.claim.value),
          paid_amount: formatValue(claimFUT.claim.paid_value),
          completed_date: formatDate(claimFUT.completed_date),
        }));
        this.total = total;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_list}: ${parseResponseError(err)}`);
      }
      this.loading = false;
    },
    handleDetails(id) {
      this.$router.push(`/finance-operations/claim-follow-up-tasks/${id}`);
    },
    onSelectChange(value) {
      this.$emit('select-changed', value);
    },
  },
};
</script>
