<template>
  <div>
    <b-modal
      id="bulk-update-modal"
      :title="isForm ? translations.list.bulk_update.form_title : translations.list.bulk_update.preview_title"
      :ok-title="isForm ? translations.list.bulk_update.btn_preview : translations.list.bulk_update.btn_save"
      :ok-disabled="saving"
      @ok="onModalOK"
      @cancel="onModalCancel">
      <div v-if="isForm">
        <p>{{ selectedItemsCount }} {{ translations.list.bulk_update.form_description }}</p>
        <b-form-group
          :label="translations.list.bulk_update.status"
          :invalid-feedback="handleError('form.status')"
          :state="isValid('form.status')">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateStatus" @change="onToggleChange('status', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-select
                v-model="form.status"
                :disabled="!form.updateStatus"
                :options="statusOptions()"/>
          </b-input-group>
        </b-form-group>
        <b-form-group :label="translations.list.bulk_update.next_action">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateNextAction" @change="onToggleChange('next_action', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-select
                v-model="form.next_action"
                :disabled="!form.updateNextAction"
                :options="nextActionOptions()"/>
          </b-input-group>
        </b-form-group>
        <b-form-group :label="translations.list.bulk_update.next_action_due_date">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateNextActionDueDate" @change="onToggleChange('next_action_due_date', ...arguments)"/>
            </b-input-group-prepend>
            <VueCtkDateTimePicker
              class="flex-grow-1"
              style="width: auto"
              format="YYYY-MM-DD"
              :disabled="!form.updateNextActionDueDate"
              :min-date="minDateAvailable"
              :label="translations.next_action_due_date_placeholder"
              formatted="ll"
              :no-button-now="true"
              :only-date="true"
              v-model="form.next_action_due_date"/>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :label="translations.list.bulk_update.note"
          :invalid-feedback="handleError('form.note')"
          :state="isValid('form.note')">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox class="mr-n2" v-model="form.updateNote" @change="onToggleChange('note', ...arguments)"/>
            </b-input-group-prepend>
            <b-form-textarea
              v-model.trim="form.note"
              :disabled="!form.updateNote" />
          </b-input-group>
        </b-form-group>

        <div v-if="showNoUpdateError" class="text-danger bold ml-3">
           <span>{{ translations.list.bulk_update.no_field_to_update_error }}</span>
        </div>
      </div>
      <div v-else>
        <p>{{ selectedItemsCount }} {{ translations.list.bulk_update.preview_description }}</p>
        <b-form-group
          :label="translations.list.bulk_update.status"
          :class="{ 'text-secondary': !form.updateStatus }">
          <div v-if="!form.updateStatus">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.status">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ statusMap[form.status] }}</span>
          </div>
        </b-form-group>
        <b-form-group
          :label="translations.list.bulk_update.next_action"
          :class="{ 'text-secondary': !form.updateNextAction }">
          <div v-if="!form.updateNextAction">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.next_action">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ nextActionMap[form.next_action] }}</span>
          </div>
        </b-form-group>
        <b-form-group
          :label="translations.list.bulk_update.next_action_due_date"
          :class="{ 'text-secondary': !form.updateNextActionDueDate }">
          <div v-if="!form.updateNextActionDueDate">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span v-if="!form.next_action_due_date">{{ translations.list.bulk_update.blank }}</span>
            <span v-else>{{ form.next_action_due_date }}</span>
          </div>
        </b-form-group>
        <b-form-group
          :label="translations.list.bulk_update.note"
          :class="{ 'text-secondary': !form.updateNote }">
          <div v-if="!form.updateNote">
            {{ translations.list.bulk_update.no_update }}
          </div>
          <div v-else>
            <span>{{ form.note }}</span>
          </div>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import translations from '@/translations';
import moment from 'moment/moment';
import { requiredIf } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import { CLAIM_FOLLOW_UP_TASK_STATUS, CLAIM_FOLLOW_UP_NEXT_ACTIONS } from '@/constants/financeOperations';

export default {
  name: 'ListBulkUpdateModal',
  mixins: [ errorHandler ],
  components: {},
  props: {
    selected: {
      type: Object,
      required: true,
    },
    selectedItemsCount: {
      type: Number,
      required: true,
    },
    isCompletedTaskTab: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      saving: false,
      isForm: true,
      showNoUpdateError: false,
      statusMap: CLAIM_FOLLOW_UP_TASK_STATUS,
      nextActionMap: CLAIM_FOLLOW_UP_NEXT_ACTIONS,
      form: {
        updateStatus: false,
        status: null,
        updateNextAction: false,
        next_action: null,
        updateNextActionDueDate: false,
        next_action_due_date: null,
        updateNote: false,
        note: null,
      },
    };
  },
  computed: {
    minDateAvailable() {
      return moment().utc().format('YYYY-MM-DD');
    },
    rules() {
      return {
        form: {
          status: {
            required: requiredIf(() => this.form.updateStatus),
          },
          note: {
            required: requiredIf(() => this.form.updateNote),
          },
        },
      };
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    save() {
      this.saving = true;

      const body = {
        items_count: this.selectedItemsCount,
        select_all: this.selected.selectAll,
        selected_ids: this.selected.selectedIds,
        is_status_completed: this.isCompletedTaskTab,
      };
      if (this.form.updateStatus) {
        body.status = this.form.status;
      }
      if (this.form.updateNextAction) {
        body.next_action = this.form.next_action || '';
      }
      if (this.form.updateNextActionDueDate) {
        body.next_action_due_date = this.form.next_action_due_date || '';
      }
      if (this.form.updateNote) {
        body.note = this.form.note;
      }

      return service.bulkUpdateClaimsFollowUpTask(body).then(() => {
        this.$emit('bulk-updated');
        this.$noty.success(`${this.selectedItemsCount} ${this.translations.list.bulk_update.save_success}`);
        this.saving = false;
        return true;
      }).catch(err => {
        this.$noty.error(`${this.translations.list.bulk_update.save_error}: ${parseResponseError(err)}`);
        this.saving = false;
        return false;
      });
    },
    statusOptions() {
      const ret = [ { value: null, text: 'Select (item)' } ];
      Object.keys(CLAIM_FOLLOW_UP_TASK_STATUS).forEach(key => {
        ret.push({ value: key, text: CLAIM_FOLLOW_UP_TASK_STATUS[key] });
      });

      return ret;
    },
    nextActionOptions() {
      const ret = [ { value: null, text: 'Select (item)' } ];
      Object.keys(CLAIM_FOLLOW_UP_NEXT_ACTIONS).forEach(key => {
        ret.push({ value: key, text: CLAIM_FOLLOW_UP_NEXT_ACTIONS[key] });
      });

      return ret;
    },
    onToggleChange(field, checked) {
      if (!checked) {
        this.form[field] = null;
      }
      this.showNoUpdateError = false;
    },
    show() {
      this.resetForm();
      this.isForm = true;
      this.$bvModal.show('bulk-update-modal');
    },
    resetForm() {
      this.form.updateStatus = false;
      this.form.status = null;
      this.form.updateNextAction = false;
      this.form.next_action = null;
      this.form.updateNextActionDueDate = false;
      this.form.next_action_due_date = null;
      this.form.updateNote = false;
      this.form.note = null;
    },
    onModalOK(e) {
      if (this.isForm) {
        e.preventDefault();
        if (this.$v.form.$invalid) {
          return;
        }
        if (!this.form.updateStatus && !this.form.updateNextAction && !this.form.updateNextActionDueDate && !this.form.updateNote) {
          this.showNoUpdateError = true;
          return;
        }
        this.isForm = false;
        return;
      }

      const success = this.save();
      if (success) {
        this.$bvModal.hide('bulk-update-modal');
      } else {
        e.preventDefault();
      }
    },
    onModalCancel(e) {
      if (!this.isForm) {
        e.preventDefault();
      }
      this.isForm = true;
    },
  },
};
</script>
