<template>
  <claim-tasks-layout>
    <div class="d-flex align-items-center justify-content-between">
      <filters
      data-testid="filters"
      ref="filters"
      @apply-filters="applyFilters"/>
      <b-button
        data-testid="export-button"
        class="mt-2"
        variant="secondary"
        @click="exportList"
        :disabled="loading">
        <feather type="download"></feather>
      </b-button>
      <div>
        <b-button
          class="ml-3 mt-2 text-nowrap"
          variant="secondary"
          @click="showBulkUpdateModal"
          :disabled="selectedItemsCount<1">
          {{ translations.list.btn_bulk_update }}
        </b-button>
        <list-bulk-update-modal
          ref="bulk-update-modal"
          :selected-items-count="selectedItemsCount"
          :selected="selected"
          :is-completed-task-tab="isCompletedTaskTab"
          @bulk-updated="onBulkUpdated"/>
      </div>
    </div>

    <div class="w-100">
      <b-tabs content-class="mt-3">
        <b-tab
        v-for="(tab, index) in tabs"
        :key="tab.key"
        :title="tab.title"
        :active="selectedTab === index"
        @click="selectTab(index)">
          <b-container fluid>
            <component
            v-if="selectedTab === index"
            :is="tab.component"
            :ref="tab.key"
            @mounted="onChildMounted"
            @select-changed="onSelectChange"
            @get-total="getTotal" />
          </b-container>
        </b-tab>
      </b-tabs>
    </div>
  </claim-tasks-layout>
</template>

<script>
import { formatDate } from '@/helpers/finance';
import translations from '@/translations';
import utils from '@/scripts/tools/utils';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import ClaimTasksLayout from '@/components/FinanceOperations/ClaimTasksLayout.vue';
import Filters from '@/components/FinanceOperations/ClaimFollowUpTasks/filters.vue';
import ClaimFollowUpTasksListOpenTasks from './listOpenTask.vue';
import ClaimFollowUpTasksListCompletedTasks from './listCompletedTask.vue';
import ListBulkUpdateModal from './listBulkUpdateModal.vue';

export default {
  name: 'ClaimFollowUpTasksList',
  components: {
    Filters,
    ClaimTasksLayout,
    ClaimFollowUpTasksListOpenTasks,
    ClaimFollowUpTasksListCompletedTasks,
    ListBulkUpdateModal,
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      selectedTab: 0,
      tabs: [
        {
          key: 'open_tasks',
          title: translations.finance_operations.claims_follow_up_tasks.tabs.open_tasks,
          component: 'ClaimFollowUpTasksListOpenTasks',
        },
        {
          key: 'completed_tasks',
          title: translations.finance_operations.claims_follow_up_tasks.tabs.completed_tasks,
          component: 'ClaimFollowUpTasksListCompletedTasks',
        },
      ],
      persistFilters: {},
      loading: true,
      total: 0,
      selected: {
        selectAll: false,
        selectedIds: [],
      },
    };
  },
  async beforeMount() {
    this.loading = true;
    // persist selected tab
    const tab = this.$route.query?.tab || 'open_tasks';
    const tabIndex = this.tabs.findIndex(t => t.key === tab);
    if (tabIndex !== -1) this.selectedTab = tabIndex;
    this.restoreFiltersFromQuery();
    this.loading = false;
  },
  async mounted() {
    this.$refs.filters.recoverFiltersFromURL(this.persistFilters);
  },
  computed: {
    isCompletedTaskTab() {
      return this.$route.query?.tab === 'completed_tasks';
    },
    selectedItemsCount() {
      if (this.selected.selectAll) {
        return this.total - this.selected.selectedIds.length;
      }
      return this.selected.selectedIds.length;
    },
  },
  methods: {
    async selectTab(index) {
      this.selectedTab = index;

      await this.sendFiltersToChildren();

      // reset query params when changing tabs
      const currentRoute = this.$route;
      this.$router.replace({
        path: currentRoute.path,
        query: {
          tab: this.tabs[index].key,
          ...this.persistFilters,
        },
      }).catch(() => {});
    },
    async applyFilters(filters = {}) {
      this.persistFilters = {};

      Object.keys(filters).forEach(key => {
        if (filters[key]?.length) {
          this.persistFilters[key] = filters[key];
        }
      });

      this.setParamsToQuery();
      await this.sendFiltersToChildren();
    },
    async onChildMounted() {
      await this.sendFiltersToChildren();
    },
    async sendFiltersToChildren() {
      const children = this.$refs[this.tabs[this.selectedTab].key];
      if (children?.length) await children[0].applyFilters(this.persistFilters, 1);
    },
    async exportList() {
      try {
        this.loading = true;
        const { data } = await service.exportClaimFollowUpTasks({
          ...this.persistFilters,
          select_all: this.selected.selectAll,
          selected_ids: this.selected.selectedIds,
          is_status_completed: this.tabs[this.selectedTab].key !== 'open_tasks',
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `claim_follow_up_tasks_export_${dateStr}.csv`;
        utils.downloadFile(data, fileName);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.export_list}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    restoreFiltersFromQuery() {
      const { query } = this.$route;
      this.persistFilters = {
        claim_keys: query?.claim_keys || [],
        assignees: query?.assignees || [],
        client_ids: query?.client_ids || [],
        system_payer_ids: query?.system_payer_ids || [],
      };

      Object.keys(this.persistFilters).forEach(key => {
        if (typeof this.persistFilters[key] === 'string') {
          this.persistFilters[key] = [ this.persistFilters[key] ];
        }
      });
    },
    setParamsToQuery() {
      const query = {
        tab: this.tabs[this.selectedTab].key || 'open_tasks',
        ...this.persistFilters,
      };

      const newQuery = new URLSearchParams(query).toString();
      const currentQuery = new URLSearchParams(this.$route.query).toString();

      if (newQuery !== currentQuery) {
        this.$router.replace({ query });
      }
    },
    onSelectChange(value) {
      this.selected = value;
    },
    getTotal(value) {
      this.total = value;
    },
    showBulkUpdateModal() {
      this.$refs['bulk-update-modal'].show();
    },
    onBulkUpdated() {
      this.applyFilters(this.persistFilters); // refresh
    },
  },
};
</script>
