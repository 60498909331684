import { render, staticRenderFns } from "./listOpenTask.vue?vue&type=template&id=60fd342b"
import script from "./listOpenTask.vue?vue&type=script&lang=js"
export * from "./listOpenTask.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports